import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"

import PremisesCard from "../search/PremisesCard"

import shuffleArray from "../../../utils/shuffleArray"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {},
  premisesCards: {
    margin: "1rem auto 2.5rem",
  },
  resultHeading: {
    fontSize: "1.5rem",
    flexGrow: "1",
    marginBottom: "2rem",
  },
  card: {
    height: "350px", //TMP
    background: "none",
  },
  emptyCard: {
    height: "350px", //TMP
    background: "none",
  },
  linkBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}))

const PremisesHighlight = ({ title, featuredPremises, additionalPremises }) => {
  const classes = useStyles()

  /*
      Radomise the order of featured premises
      Slice 0-6
      If less than 6, fill with other spaces
    */
  const selectedPremises = shuffleArray(featuredPremises)

  return (
    <Container className={classes.content}>
      <Box className={classes.premisesCards}>
        {title && (
          <Typography
            className={classes.resultHeading}
            variant="h4"
            component="h2"
          >
            {title}
          </Typography>
        )}
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          {selectedPremises.slice(0, 6).map((premises, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <PremisesCard className={classes.card} premises={premises} />
              </Grid>
            )
          })}
        </Grid>
        <Box className={classes.linkBox}>
          <Link
            className={classes.link}
            to={"/toimitilat"}
            text={"Etsi toimitilat"}
          >
            <Button
              variant="contained"
              color="secondary"
              disableRipple={true}
              disableFocusRipple={true}
            >
              Katso kaikki toimitilat
            </Button>
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

PremisesHighlight.propTypes = {
  title: PropTypes.string,
}

PremisesHighlight.defaultProps = {}

export default PremisesHighlight
